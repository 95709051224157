<template>
    <inner-page-card :header-bullet="true" :align-end="true" :link-back="true">
        <template #header-area>
            <div class="fl-x-res fl-a-c w-100">
                <!--                    <div class="bullet"></div>-->
                <div class="">
                    <h5 class="text-white">Auction ID <span class="mr-2">23454785</span></h5>
                </div>
                <div class="">
                    <!--                        <span>Live Auction</span>-->
                    <span class="px-2"><img src="../../assets/web/icons/icons8-chevron-12.png" alt=""></span>
                    <span class="font-inter-medium">Plot No. 13, Land Property at Kakkanad, Ernakulam</span>
                </div>
            </div>
        </template>
        <template #buttons>
            <btn design="basic-a" shadow="5" color="primary"
                 class="mt-3 mt-lg-0 pr-4 btn-shadow-hover border-none bg-secondary-600-trans icon-chervron-right" text="Auction Details">
            </btn>
        </template>
        <!--        <template #note>-->
        <!--            <span class="text-secondary">Note</span> :-->
        <!--            <span class="fs&#45;&#45;1">This page will automatically refresh in every 60 seconds</span>-->
        <!--        </template>-->
        <div class="fl-x fl-j-c mb-3">
            <div class="w-4re avatar-img-abs mb-2">
                <img src="../../assets/web/homee/avatar.jpg" alt="" class="outline-secondary border-rounded w-h-100">
            </div>
        </div>
        <div class="font-inter-regular fs-3 text-center">
            <span class="font-inter-bold">Mr. Divin Sunny</span>
            <span> / </span>
            <span>Bidder ID</span>
            &nbsp;
            <span>285989</span>
            &nbsp;
            <span>won this auction !</span>
        </div>
        <div class="fl-x fl-j-c mt-3 mb-3">
            <div class="fl-x-res">
                <div class="font-inter-medium pos-r px-3">
                    <div class="line-right-2"></div>
                    <span>Date :</span>
                    <span class="pl-1">08 June 2022</span>
                </div>
                <div class="font-inter-medium pos-r px-3">
                    <div class="line-right-2"></div>
                    <span>Start Time :</span>
                    <span class="pl-1">08 June 2022</span>
                </div>
                <div class="font-inter-medium px-3">
                    <span>End Time :</span>
                    <span class="pl-1">10.00 am</span>
                    <span class="fs-lg--2">IST</span>
                </div>
            </div>
        </div>

        <auction-details-table></auction-details-table>
        <tabs align="center" class="c-tabs-gray">
            <tab title="Access Logs">
                <custom-unordered-list :items="accessLogs">
                </custom-unordered-list>
            </tab>
            <tab title="Summary">
                <custom-unordered-list :items="summary">
                </custom-unordered-list>
            </tab>
            <tab title="Reports">
                <custom-unordered-list :items="reports">
                </custom-unordered-list>
            </tab>
        </tabs>
    </inner-page-card>
</template>

<script>
import AuctionDetailsTable from '@components/auction/live-auction/AuctionDetailsTable';
import CustomUnorderedList from '@components/ui/CustomUnorderedList';

export default {
    name       : 'LiveAuctionDetailsPage',
    components : { CustomUnorderedList, AuctionDetailsTable },
    data () {
        return {
            accessLogs : [
                'Bidder ID 736528 submitted a bid of Rs. 35,200,00.00',
                'Bidder ID 736528 submitted a bid of Rs. 35,200,00.00',
                'Auction ID 3761985 started at 2.00 pm'
            ],
            summary : [
                'Bidder ID 736528 submitted a bid of Rs. 35,200,00.00',
                'Bidder ID 736528 submitted a bid of Rs. 35,200,00.00',
                'Auction ID 3761985 started at 2.00 pm'
            ],
            reports : [
                'Bidder ID 736528 submitted a bid of Rs. 35,200,00.00',
                'Bidder ID 736528 submitted a bid of Rs. 35,200,00.00',
                'Auction ID 3761985 started at 2.00 pm'
            ]
        };
    }
};
</script>

<style scoped lang="scss">
.icon-chervron-right {
    position: relative;

    &::after {
        content: '';
        position: absolute;
        right: 0.5rem;
        top: 0;
        width: 16px;
        height: 100%;
        border-left: 1px solid rgba(128, 128, 128, 0.6);
        background: url("../../assets/web/icons/chevron-right.png");
        background-position: 0% 50%;
    }
}

.w-4re {
    //width: 4.2rem;
    width: 4.9rem;
    height: 4.9rem;
}
</style>
